import { z } from 'zod';

const baseSchema = z.object({
  BASE_URI: z.string(),
  PREMIUM_PRICE: z.string(),
  PREMIUM_MONTHS: z.number(),
  PAYMENT_ADDRESS: z.string(),
  SHAREMINT_CUT: z.number(),
  DISPERSE_ADDRESS: z.string(),
  PREMIUM_CHAIN_ID: z.number(),
  ALCHEMY_ID: z.string().optional(),
  PRIVY_APP_ID: z.string(),
  SHOW_PARTNERS_TAB: z.boolean().optional(),
});

const productionEnvSchema = z.object({
  AMPLITUDE_API_KEY: z.string(),
  HOTJAR_ID: z.string(),
  CLOUDINARY_SLUG: z.string(),
  SENTRY_DSN: z.string(),
  SENTRY_TRACE_SAMPLE_RATE: z.number().max(1).min(0).optional(),
  SHAREMINT_SLUG: z.string(),
  CRISP_WEBSITE_ID: z.string().optional(),
});

export const publicEnvSchema = baseSchema.merge(
  process.env.VERCEL_ENV === 'production' ? productionEnvSchema : productionEnvSchema.partial()
);

export const publicConfig = publicEnvSchema.parse({
  BASE_URI:
    process.env.NEXT_PUBLIC_BASE_URI ||
    (typeof window !== 'undefined' ? window.location.origin : ''),
  PAYMENT_ADDRESS:
    process.env.NEXT_PUBLIC_PAYMENT_ADDRESS || '0x691E3bbbc32b4435917A9B22c81f2c2cCfaE2601', // 'sharemint.eth',
  PREMIUM_PRICE: process.env.NEXT_PUBLIC_PREMIUM_PRICE || '1',
  PREMIUM_MONTHS: process.env.NEXT_PUBLIC_PREMIUM_MONTHS || 6,
  PREMIUM_CHAIN_ID: process.env.NEXT_PUBLIC_PREMIUM_CHAIN === 'goerli' ? 5 : 1,
  ALCHEMY_ID: process.env.NEXT_PUBLIC_ALCHEMY_ID,
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
  CLOUDINARY_SLUG: process.env.NEXT_PUBLIC_CLOUDINARY_SLUG || 'sharemint',
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE: process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE
    ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE) || undefined
    : undefined,
  SHAREMINT_SLUG: process.env.NEXT_PUBLIC_SHAREMINT_SLUG || 'sharemint',
  SHAREMINT_CUT: process.env.NEXT_PUBLIC_SHAREMINT_CUT
    ? parseFloat(process.env.NEXT_PUBLIC_SHAREMINT_CUT)
    : 2.5,
  DISPERSE_ADDRESS:
    process.env.NEXT_PUBLIC_DISPERSE_ADDRESS || '0xd152f549545093347a162dce210e7293f1452150',
  SHOW_PARTNERS_TAB: process.env.NEXT_PUBLIC_SHOW_PARTNERS_TAB === 'true',
  CRISP_WEBSITE_ID: process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
  PRIVY_APP_ID: process.env.NEXT_PUBLIC_PRIVY_APP_ID,
});

export const BASE_URI = publicConfig.BASE_URI;
export const PAYMENT_ADDRESS = publicConfig.PAYMENT_ADDRESS;
export const PREMIUM_PRICE = publicConfig.PREMIUM_PRICE;
export const PREMIUM_MONTHS = publicConfig.PREMIUM_MONTHS;
export const PREMIUM_CHAIN_ID = publicConfig.PREMIUM_CHAIN_ID;
export const ALCHEMY_ID = publicConfig.ALCHEMY_ID;
export const AMPLITUDE_API_KEY = publicConfig.AMPLITUDE_API_KEY;
export const HOTJAR_ID = publicConfig.HOTJAR_ID;
export const CLOUDINARY_SLUG = publicConfig.CLOUDINARY_SLUG;
export const SENTRY_DSN = publicConfig.SENTRY_DSN;
export const SENTRY_TRACE_SAMPLE_RATE = publicConfig.SENTRY_TRACE_SAMPLE_RATE;
export const SHAREMINT_SLUG = publicConfig.SHAREMINT_SLUG;
export const SHAREMINT_CUT = publicConfig.SHAREMINT_CUT;
export const DISPERSE_ADDRESS = publicConfig.DISPERSE_ADDRESS as `0x${string}`;
export const SUPPORT_EMAIL = 'team@sharemint.xyz';
export const CAL_LINK = 'https://calendly.com/amirexodia/30min';
export const SHOW_PARTNERS_TAB = publicConfig.SHOW_PARTNERS_TAB;
export const CRISP_WEBSITE_ID = publicConfig.CRISP_WEBSITE_ID;
export const PRIVY_APP_ID = publicConfig.PRIVY_APP_ID;
